<template>
  <div class="home">
    <div class="">
      <div class="top">
        <div class="introduce">
          <div class="words">
            <span style="display: block; line-height: 68px"
              >国际期刊联盟有限公司与优诚医学</span
            >
            <span style="line-height: 68px">联合下属医学期刊</span>
          </div>
          <div class="but" @click="centerDialogVisible = true" v-if="false">
            <img class="tg" src="../static/index/tougao.png" alt="" />
            <span>点击投稿</span>
          </div>
        </div>
      </div>
      <div class="journalism">
        <div class="jtitle">
          <div class="jl">
            <img class="jlimg" src="../static/index/journalism.png" alt="" />
            <span>新闻公告</span>
          </div>
          <div class="jr" @click="more">
            <span class="jr_txt">查看更多</span>
            <img class="jrimg" src="../static/index/youjiantou.png" alt="" />
          </div>
        </div>
        <div class="contents">
          <div
            class="cont"
            v-for="(it, i) in list.data"
            :key="i"
            @click="detail(it.id)"
          >
            <div class="contl">
              <span class="dian"></span>
              <span class="cont_txt">{{ it.title }}</span>
            </div>
            <span class="cont_time">{{ it.ctime }}</span>
          </div>
        </div>
      </div>
      <div class="journalism" v-if="false">
        <div class="jtitle">
          <div class="jl">
            <img class="jlimg" src="../static/index/bianjidianhua.png" alt="" />
            <span>在线编辑电话</span>
          </div>
          <div class="jr" @click="moret">
            <span class="jr_txt">查看更多</span>
            <img class="jrimg" src="../static/index/youjiantou.png" alt="" />
          </div>
        </div>
        <div class="editphone">
          <div class="detail" v-for="(it, i) in arr" :key="i">
            <span class="phonum">{{ it.mobile }}</span>
            <span class="call">(社会编辑-{{ it.username }})</span>
          </div>
        </div>
      </div>
    </div>

    <div>
      <el-dialog
        :visible.sync="centerDialogVisible"
        width="25%"
        center
        top="25vh"
        custom-class="dia"
        :show-close="false"
        :close-on-click-modal="true"
      >
        <span class="zz">扫描下方二维码注册投稿</span>
        <img class="rect_img" src="../static/index/pr.png" alt="" />
        <span class="lv">注册流程:</span>
        <div class="cc">
          <span class="r_txt">1. 扫码进入小程序</span>
          <span class="r_txt">2. 点击"登录享服务"</span>
          <span class="r_txt">3. 登录并绑定手机号</span>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { testGet, socialEditor } from "../api/index";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      centerDialogVisible: false,
      list: [],
      arr: "",
    };
  },
  mounted() {
    this.getlist();
    this.getphone();
  },
  methods: {
    more() {
      this.$router.replace("xinwen");
    },
    moret() {
      this.$router.replace("bianji/bianji");
    },
    getphone() {
      socialEditor().then((res) => {
        this.arr = res.data.data;
        if (res.data.data.length > 9) {
          this.arr = this.arr.slice(0, 9);
        }
        // console.log(res.data.data.length,'cccc')
      });
    },
    getlist() {
      testGet({ page: 1, limit: 3 })
        .then((res) => {
          this.list = res.data.data;
          console.log(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    detail(id) {
      console.log(id, 123456);
      this.$router.push({
        path: "/xinwen/xwlist",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
<style lang="scss">
.home {
  .msi {
    padding: auto;
  }
  .dia {
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 36px 0;
      height: 100%;
      color: #222222;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      text-align: center;
      .zz {
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        text-align: center;
      }
      .rect_img {
        display: block;
        width: 230px;
        height: 230px;
        margin: 4px auto;
      }
      .lv {
        color: #1ca09c;
        margin-bottom: 10px;
        display: block;
      }
      .cc {
        text-align: left;
        width: 200px;
        margin: 0 auto;
        .r_txt {
          display: block;
          line-height: 32px;
        }
      }
    }
  }
}
.top {
  width: 100%;
  height: 450px;
  background: url("https://ht.yuicloud.com/web_page/magazine_website_index.png") no-repeat;
  .introduce {
    width: 1034px;
    margin: 0 auto;
    padding-top: 50px;
    .words {
      font-size: 46px;
      font-family: SourceHanSansSC-Bold, SourceHanSansSC;
      font-weight: bold;
      color: #222222;
      text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.5);
    }
    .but {
      width: 300px;
      height: 60px;
      text-align: center;
      background-color: #ff9f1e;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 36px;
      cursor: pointer;
      user-select: none;
      .tg {
        width: 26px;
        height: 26px;
        margin-right: 11px;
      }
    }
  }
}
.journalism {
  width: 1182px;
  margin: 36px auto 0;
  .jtitle {
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #efefef;
    user-select: none;

    .jl {
      display: flex;
      align-items: center;

      .jlimg {
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }
    }
    .jr {
      font-size: 16px;
      font-weight: 400;
      color: #1ca09c;
      cursor: pointer;

      .jrimg {
        width: 20px;
        height: 12px;
        margin-left: 12px;
      }
    }
  }
  .editphone {
    background-color: #fafafa;
    padding: 0 24px 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .detail {
      width: 292px;
      margin: 24px 0 0;
     
      .phonum {
        margin-right: 17px;
      }
    }
  }
  .contents {
    background-color: #fafafa;
    padding: 24px;
    .cont:hover {
      color: #ff9502;
    }
    .cont {
      padding: 12px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #222222;
      cursor: pointer;
      .dian {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #1ca09c;
        vertical-align: middle;
        margin: 0 8px 2px 0;
      }
      .cont_txt {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
      }
      .cont_time {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
    }
  }
}
</style>