import http from '../../config.js'
export function testGet(data){
    return http({
        method:'get',
        url:'api/magazine/noticeList',
        params:data
   })
}
export function noticeInfo(data){
    return http({
        method:'get',
        url:'api/magazine/noticeInfo',
        params:data
   })
}
export function socialEditor(data){
    return http({
        method:'get',
        url:'api/magazine/socialEditor',
        params:data
   })
}
export function contribution(data){
    return http({
        method:'get',
        url:'api/magazine/contribution_note',
        params:data
   })
}