import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'


Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/qikan',
        name: 'qikan',
        component: () =>import ('../views/qikan.vue')
    },
    {
        path: '/tougao',
        name: 'tougao',
        component: () =>import ('../views/tougao.vue')
    },
    {
        path: '/xinwen',
        name: 'xinwen',
        component: () =>import ('../views/xinwen.vue'),
    },
    {
        path: '/lianxi',
        name: 'lianxi',
        component: () =>import ('../views/lianxi.vue')
    },
    {
        path:'/xinwen/xwlist',
        name:'xwlist',
        component: () =>import ('../views/xinwen/xwlist.vue'),
    },
    {
        path:'/bianji/bianji',
        name:'bianji',
        component: () =>import ('../views/bianji/bianji.vue'),
    }
]

const router = new VueRouter({
    routes
})

export default router